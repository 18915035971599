
let lastTimestamp = Date.now()

function getPackage () {
  return window.cpexPackage || window.cpexPackageFormats
}

function decorateLog (args) {
  args = [].slice.call(args)
  args.unshift('display: inline-block; color: #fff; background: #555; padding: 1px 4px; border-radius: 3px;')
  args.unshift('%cCPEx Package')
  return args
}

// Deep copy
export function clone (source) { return source ? JSON.parse(JSON.stringify(source)) : source }

// Shows console logs, only when debug mode is on ('debug' in URL)
export function cpexLog () {
  if (getPackage().debugMode) {
    const now = Date.now()
    Array.prototype.push.call(arguments, now - lastTimestamp) // Add timestamp
    lastTimestamp = now
    console.log.apply(this, decorateLog(arguments))
  }
}

// Shows console log is large font size
export function cpexLogHeadline (text) {
  console.log('%cCPEx Package%c ' + text, 'display: inline-block; color: #fff; background: #555; padding: 1px 4px; border-radius: 3px;', 'font-size: 15px; padding-top: 10px; font-weight: bold; border-bottom: none')
}

// Shows console warnings with a common prefix
export function cpexWarn () {
  console.warn.apply(this, decorateLog(arguments))
}

// Shows console errors with a common prefix. Message is mandatory
export function cpexError (message, error) {
  if (error instanceof Error) {
    error.message = message + ', reason: ' + error.message
  } else {
    error = new Error(message || 'missing error message')
  }
  const main = getPackage()
  if (main.sentry && main.settingsLoaded === true) {
    main.sentry.captureException(error)
  }
  console.error.apply(this, decorateLog(arguments))
  return error
}

// Safe check for object type
export function isObject (obj) {
  return (typeof obj === 'object') && obj !== null && Array.isArray(obj) === false
}

// Checks if it's array and has content
export function isFilledArray (arr) {
  return Array.isArray(arr) && arr.length > 0
}

// Check adsObject existance and structure
export function isAdsObject (obj) {
  return isObject(obj) ? isObject(obj.ball) : false
}

/**
 * Extracts value of a keyword with regexp, anything after the keyword: https://regex101.com/r/5l5mnF/7
 * Has to account for either white space or slash after the value
 */
export function regexGetValue (text, keyword) {
  const rex = new RegExp(keyword + '=(?<value>[^a-zA-Z]+)(\/| )', 'gm') // eslint-disable-line
  const parsed = rex.exec(text)
  if (parsed && parsed.groups) { return parsed.groups.value }
}

// Extracts first html comment with regexp: https://regex101.com/r/xh5Mij/2
export function regexGetFirstComment (text) {
  const parsed = text.match(/<!--(?<value>.+?)-->/m)
  if (parsed && parsed.groups) { return parsed.groups.value }
}

// Load any script asynchronously, resolve when loaded
export function loadScript (url, name) {
  return new Promise((resolve, reject) => {
    if (typeof url !== 'string') {
      cpexLog('LoadScript: URL  for ' + name + ' not given, is it intentional?')
      return resolve()
    }
    const script = document.createElement('script')
    script.onload = () => { resolve() }
    script.onerror = (e) => { reject(e) }
    script.src = url
    document.head.appendChild(script)
  })
}

// Adds custom CSS
export function addStyle (css) {
  const styleEl = document.createElement('style')
  styleEl.innerHTML = css
  document.head.appendChild(styleEl)
  return styleEl
}

// Deep merge two objects. One drawback is that nested objects are referenced, not cloned
export function deepMerge (target, source) {
  if (typeof target !== 'object' || typeof source !== 'object') return false // target or source or both ain't objects, merging doesn't make sense
  for (const prop in source) {
    if (isDOMObject(source[prop])) { // don't clone DOM elements
      target[prop] = source[prop]
      continue
    }
    // take into consideration only object's own properties
    if (!source.hasOwnProperty(prop)) continue // eslint-disable-line
    if (prop in target) { // handling merging of two properties with equal names
      if (typeof target[prop] !== 'object') {
        target[prop] = source[prop]
      } else {
        if (typeof source[prop] !== 'object') {
          target[prop] = source[prop]
        } else {
          if (target[prop].concat && source[prop].concat) { // two arrays, target replaces source - so that defaults can be rewritten (eg analytics)
            target[prop] = source[prop]
            // target[prop] = target[prop].concat(source[prop]) // backup: merging of arrays
          } else { // two objects get merged recursively
            target[prop] = deepMerge(target[prop], source[prop])
          }
        }
      }
    } else { // new properties get added to target
      target[prop] = source[prop]
    }
  }
  return target
}

// Official Didomi implementation script
export function addTCFStub () {
  window.gdprAppliesGlobally = true
  function addFrame (frameName) {
    if (!window.frames[frameName]) {
      if (document.body && document.body.firstChild) {
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.name = frameName
        iframe.title = frameName
        document.body.insertBefore(iframe, document.body.firstChild)
      } else {
        setTimeout(function () { addFrame(frameName) }, 5)
      }
    }
  }

  function createStub (fnName, bufferName, postMessageCall, postMessageReturn, localCommandProcess) {
    function stub (command, version, callback, parameter) {
      if (typeof callback !== 'function') { return }
      if (!window[bufferName]) { window[bufferName] = [] }
      let processed = false
      if (localCommandProcess) { processed = localCommandProcess(command, parameter, callback) }
      if (!processed) { window[bufferName].push({ command, version, callback, parameter }) }
    }

    stub.stub = true
    stub.stubVersion = 2

    function messageHandler (event) {
      if (!window[fnName] || window[fnName].stub !== true) { return } // Didomi stub is not registered, abort here. That's Didomi way of not handling messages after the SDK has loaded and taken over
      if (!event.data) { return }

      const messageIsString = typeof event.data === 'string'
      let message
      try { message = messageIsString ? JSON.parse(event.data) : event.data } catch (e) { return } // There was an error parsing the JSON, return here as there is not much we can do. Most likely, the message wasn't for us anyway

      if (message[postMessageCall]) { // It is a message for a CMP, forward it to the CMP handler
        const data = message[postMessageCall]
        function getCallback (returnValue, success) {
          const response = {}
          response[postMessageReturn] = { returnValue, success, callId: data.callId }
          event.source.postMessage(messageIsString ? JSON.stringify(response) : response, '*')
        }
        window[fnName](data.command, data.version, getCallback, data.parameter)
      }
    }

    if (typeof window[fnName] !== 'function') {
      window[fnName] = stub // No stub on the page yet, load Didomi stub
      window.addEventListener ? window.addEventListener('message', messageHandler, false) : window.attachEvent('onmessage', messageHandler) // Register the postMessage handler
    }
  }

  createStub('__tcfapi', '__tcfapiBuffer', '__tcfapiCall', '__tcfapiReturn')
  addFrame('__tcfapiLocator')
}

// Save segments to cookie for later use
export function addAzureExport () {
  window.cX = window.cX || {}
  window.cX.callQueue = window.cX.callQueue || []
  window.cX.callQueue.push(['invoke', () => {
    window.__tcfapi('addEventListener', 2, (data, success) => {
      if (success === false) { return }
      if (data.vendor.consents[570]) {
        const pianoId = window.cX.getCxenseUserId()
        if (pianoId) {
          addElement('iframe', document.body, { src: 'https://cdn.cpex.cz/cookies/save.html?name=excp&time=1209600&data=' + encodeURIComponent(pianoId), width: 0, height: 0, style: 'border: none; display: block;' })
        }
      }
    })
  }])
}

// Shows visual debug "tags" on banners
export function displayMetaData (elementId, creativeMetaData) {
  let element = document.getElementById(elementId)
  if (!element) { return cpexWarn(`Element id '${elementId}' not found`) }
  // Check if the target moved. Used for skin
  const newId = element.getAttribute('data-target-id-moved')
  if (document.getElementById(newId)) { element = document.getElementById(newId) }

  if (element) {
    // Wrappers
    let relativeWrapper = element.getElementsByClassName('debugTags')[0]
    let absoluteWrapper
    let regularTags
    let extraTags

    // Find current wrappers or create new ones
    if (relativeWrapper) {
      absoluteWrapper = relativeWrapper.children[0]
      regularTags = element.getElementsByClassName('regularTags')[0]
      extraTags = element.getElementsByClassName('extraTags')[0]
    } else {
      relativeWrapper = document.createElement('div') // needed in cases when the parent is not positioned
      relativeWrapper.classList.add('debugTags')
      relativeWrapper.style.cssText = 'position: relative; display: block; height: 0px'
      element.prepend(relativeWrapper)

      absoluteWrapper = document.createElement('div')
      absoluteWrapper.style.cssText = 'position: absolute; top: 0px; left: 0px; padding: 10px; z-index: 99999'
      relativeWrapper.appendChild(absoluteWrapper)

      regularTags = document.createElement('span')
      regularTags.classList.add('regularTags')
      absoluteWrapper.appendChild(regularTags)
      extraTags = document.createElement('span')
      extraTags.classList.add('extraTags')
      absoluteWrapper.appendChild(extraTags)
    }

    // Tags
    for (const [key, value] of Object.entries(creativeMetaData)) {
      const tag = document.createElement('span')
      switch (key) {
        case 'hb':
          tag.innerHTML = '<b>HB</b>, bidder: <b>' + value.bidder + '</b>, cpm: <b>' + Math.round(value.cpm * 100) / 100 + '</b>, size: <b>' + value.size + '</b>'
          if (value.hbkey) { tag.innerHTML += ', <b>hbKey:</b> ' + value.hbkey }
          tag.style.cssText = 'color: black; font-size: 8px; display: inline-block; padding: 4px; margin: 1px; border-radius: 3px; background: #1a73e8; color: #fff; box-shadow: 0px 0px 2px #888A'
          extraTags.appendChild(tag)
          break
        case 'json':
          tag.innerHTML = '<b>JSON</b>'
          tag.style.cssText = 'color: black; font-size: 8px; display: inline-block; padding: 4px; margin: 1px; border-radius: 3px; background: #8fe81a; color: #fff; box-shadow: 0px 0px 2px #888A'
          extraTags.appendChild(tag)
          break
        case 'customType':
          if (!extraTags.querySelector('.customType')) { // prevent duplication, can come from multiple sources
            tag.innerHTML = 'CPEx Format: <b>' + value + '</b>'
            tag.style.cssText = 'color: black; font-size: 8px; display: inline-block; padding: 4px; margin: 1px; border-radius: 3px; background: #ee2024; color: #fff; box-shadow: 0px 0px 2px #888A'
            tag.classList.add('customType')
            extraTags.appendChild(tag)
          }
          break
        default:
          tag.innerHTML = key + ': <b>' + value + '</b>'
          tag.style.cssText = 'color: black; font-size: 8px; display: inline-block; padding: 4px; margin: 1px; border-radius: 3px; background: #eee; box-shadow: 0px 0px 2px #888A'
          regularTags.appendChild(tag)
          break
      }
    }
  }
}

// Returns boolean whether the browser is on a mobile device
export function isMobile () {
  return navigator.userAgent.match(/Mobi/)
}

export function awaitDOM () {
  return new Promise(resolve => document.readyState !== 'loading' ? resolve() : document.addEventListener('DOMContentLoaded', resolve)).catch(e => cpexError('DOM loaded failed', e))
}

// Limits value between min and max, inclusive
export function clamp (val, min, max) {
  return val > max ? max : val <= min ? min : val
}

// Adds iframe for a creative to be rendered into
export function addIframe (element, attributes, callback) {
  // create iframe
  const iframe = document.createElement('iframe')
  // set standard attributes
  iframe.style.cssText = 'border:none; display:block; overflow:hidden; border:none; margin:auto;'
  // add to DOM
  element.appendChild(iframe)
  // add callback (has to be after added to DOM, otherwise it triggers twice)
  if (typeof callback === 'function') { iframe.onload = () => { callback(iframe) } }
  // assign all properties as html node attributes
  Object.assign(iframe, attributes)
  // set non-standard (but helpful) attributes
  iframe.setAttribute('scrolling', 'no')
  iframe.setAttribute('marginwidth', '0')
  iframe.setAttribute('marginheight', '0')
  return iframe
}

// Puts creative into an iframe document
export function fillIframeDoc (doc, creative) {
  doc.open('text/html', 'replace')
  doc.write(creative)
  doc.close()
}

export function hideAdContent (element) {
  const el = document.getElementById(element)
  if (el) {
    const children = document.getElementById(element).children
    for (const child of children) {
      if (!child.classList.contains('debugTags')) {
        child.style.display = 'none'
      }
    }
  }
}

// Returns boolean. Hopefully will be supplanted by a native method later
export function isDOMObject (obj) {
  // eslint-disable-next-line
  return obj instanceof Element
}

// Adds 1px image which passes tracking data to a server with its url
export function addPixel (url) {
  const el = document.createElement('img')
  el.setAttribute('src', url)
  el.style.width = '1px'
  el.style.height = '1px'
  el.style.position = 'absolute'
  el.style.bottom = '0px'
  el.style.right = '0px'
  el.style.border = 'none'
  document.body.appendChild(el)
}

// Decode UFT-8 Base64 encoded string, source: https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
export function b64DecodeUnicode (str) {
  return decodeURIComponent(window.atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

// Manual template literal injection, for reusability. Based on this snippet https://stackoverflow.com/a/55594573/3163669
export function replaceTemplateLiteral (str, obj) {
  return str.replace(/\${(.*?)}/g, (x, g) => obj[g])
}

// Crop text and add elipsis
export function cropText (text, maxLength) {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text
}

// DEPRECATED. Takes HTML template and fills it's variables from Native data ad
export function fillNativeTemplate (native, html, config) {
  return replaceTemplateLiteral(html, {
    link: native.link.url,
    title: cropText(native.assets[0].title.text, config?.titleLength || 45),
    desc: cropText(native.assets[1].data.value, config?.descLength || 90),
    img: native.assets[2].img.url,
    sponsor: '' // not available
  })
}

// Takes HTML template and fills it's variables from Prebid Native ad
export function fillOrtbNativeTemplate (native, html, config) {
  return replaceTemplateLiteral(html, {
    link: native.clickUrl,
    title: cropText(native.title, config?.titleLength || 45),
    desc: native.body ? cropText(native.body, config?.descLength || 90) : '',
    img: native.image?.url,
    sponsor: native.sponsoredBy || native.sponsored || ''
  })
}

// Custom version for Equativ/Smart native
/*
export function fillSmartNativeTemplate (native, html, config) {
  return replaceTemplateLiteral(html, {
    link: native.clickUrl,
    title: cropText(native.title, config?.titleLength || 45),
    desc: native.data ? cropText(native.data, config?.descLength || 90) : '',
    img: native.imgUrl
  })
}
*/

// Fires tracking pixels for whatever paths it finds in the data
export function triggerTracking (data) {
  function firePathOrArray (idk) {
    if (idk) {
      if (Array.isArray(idk)) {
        idk.forEach((item) => { addPixel(item) })
      } else if (typeof idk === 'string') {
        addPixel(idk)
      }
    }
  }
  if (data.measurePixel) { firePathOrArray(data.measurePixel) }
  if (data.data.native) {
    if (data.data.native.impressionTrackers) { firePathOrArray(data.data.native.impressionTrackers) }
    if (data.data.native.javascriptTrackers) { firePathOrArray(data.data.native.javascriptTrackers) }
  }
}

// Check if Prebid is loaded
export function isPrebidLoaded () {
  return !!(typeof window.pbjs === 'object' && window.pbjs.version)
}

// Test adblock with ajax request, which gives us access to HTTP response codes
export function hasAdblock (prebidPath) {
  return new Promise((resolve, reject) => {
    try {
      const xhr = new window.XMLHttpRequest()
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) { // done
          switch (xhr.status) {
            case 0: return resolve(true) // failed
            case 200: return resolve(false) // success
          }
        }
      }
      xhr.open('GET', prebidPath, true)
      xhr.setRequestHeader('Cache-Control', 'no-cache')
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
      xhr.send()
    } catch (e) { reject(cpexWarn('AdBlock testing failed', e)) }
  })
}

// Returns googletag slot based on it's adUnit path in GAM. Expects googletag to be present.
export function gamGetElementByPath (adUnitPath) {
  for (const slot of window.googletag.pubads().getSlots()) {
    if (slot.getSlotId().getName() === adUnitPath) { return slot }
  }
}

export async function getIpAddress () {
  let ip = window.localStorage.getItem('pdip')
  if (ip) {
    ip = JSON.parse(ip)
    if (ip.ip4 || ip.ip6) { return ip }
  }
  const response = await fetch('https://api64.ipify.org?format=json')
  if (response.status === 200) {
    const res = await response.json()
    if (res.ip.includes('.')) { // IPv4
      window.localStorage.setItem('pdip', JSON.stringify({ ip4: res.ip }))
      return { ip4: res.ip }
    } else if (res.ip.includes(':')) { // IPv6
      window.localStorage.setItem('pdip', JSON.stringify({ ip6: res.ip }))
      return { ip6: res.ip }
    } else return {}
  } else return {}
}

// Returns custom user identification info for id5
export async function getId5PartnerData (ip) {
  const payload = { 9: window.location.hostname, 8: window.location.href, 12: navigator.userAgent }
  if (ip.ip4) { payload[10] = ip.ip4 }
  if (ip.ip6) { payload[11] = ip.ip6 }
  const pdRaw = Object.keys(payload).map(key => key + '=' + encodeURIComponent(payload[key])).join('&')
  return window.btoa(pdRaw)
}

// Shortens DOM element adding
export function addElement (name, container, attributes) {
  const el = document.createElement(name)
  Object.assign(el, attributes)
  if (typeof attributes !== 'undefined' && attributes.class) { el.setAttribute('class', attributes.class) }
  return container.appendChild(el)
}

// Reads a cookie by name, returns it's value or null
export function getCookie (name) {
  return (name = new RegExp('(?:^|;\\s*)' + ('' + name).replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + '=([^;]*)').exec(document.cookie)) && name[1]
}

// Get GDPR consent string from CMP
export function getConsent () {
  return new Promise((resolve, reject) => {
    try {
      window.__tcfapi('addEventListener', 2, (tcData, success) => {
        if (success && tcData && tcData.tcString) {
          resolve(tcData.tcString)
        } else { // don't throw error for 3rd party code
          reject() // eslint-disable-line
        }
      })
    } catch (e) {
      cpexError('CMP not responding properly', e)
      reject(e)
    }
  })
}

export function setCookie (name, duration, value = 'true', domain) {
  const now = new Date()
  now.setTime(now.getTime() + duration * 1000) // in seconds
  if (domain) {
    domain += '; SameSite=None; Secure'
  } else {
    const parts = location.hostname.split('.')
    parts.shift()
    domain = parts.join('.')
  }
  const cookie = name + '=' + value + '; expires=' + now.toUTCString() + '; path=/; domain=' + domain
  document.cookie = cookie
}

export function getRandomInt (a, b) {
  return Math.floor(Math.random() * (b - a + 1)) + a
}

export async function getThirdPartyCookieSupport () {
  let done = false
  return new Promise((resolve, reject) => {
    window.addEventListener('message', (event) => {
      if (event.origin !== 'https://cdn.cpex.cz') { return }
      if (event.data.type === 'cpexRead' && event.data.cookie === 'tpc') {
        done = true
        event.data.value === 'supported' ? resolve(1) : resolve(0)
      }
    }, false)
    const failsafe = () => { if (!done) { resolve(0) } }
    const save = addElement('iframe', document.body, { src: 'https://cdn.cpex.cz/cookies/save.html?name=tpc&time=1209600&data=supported', width: 0, height: 0, style: 'border: none; display: block' })
    save.onload = () => { addElement('iframe', document.body, { src: 'https://cdn.cpex.cz/cookies/read.html?name=tpc', width: 0, height: 0, style: 'border: none; display: block' }) }
    setTimeout(failsafe, 1000) // failsafe
  })
}
